import React from "react"
import "./accordion.scss"

const Accordion = ({ props }) => {


    return (
        <>
            <div className="faq-container">
                <div className="uk-card uk-card-muted">
                    {
                        props.title ?
                            <div>
                                <h2 className="title uk-text-center">{props.title}</h2>
                                <hr style={{ width: "50px", margin: "0px auto 30px auto" }} />
                            </div>
                            : ""
                    }
                    <div data-uk-accordion className=" faq-accordion">
                        {
                            props.contents.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <a className="uk-accordion-title faq-question" href="#0">
                                            {item.Question}
                                        </a>
                                        <div className="uk-accordion-content faq-answer">
                                            <p>{item.Answer}</p>
                                            {((item.URL)?.length > 0) ?
                                                <a href={`/${item.URL}`} className="accordion-answer-read-more"
                                                    //target="_blank"
                                                >read more</a>
                                                : ''
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accordion