import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import Accordion from "../components/accordion/accordion"
import { posts } from "../utils/generalInformation"


const Blog = () => {


  return (
    <Layout
      SEOTitle={"Blog - United Legal Assistance"}
      SEODescription={"The rise of Insurance Technology has come as a surprise to those who view the industry of lacking forward thinking."}
      footerDisclaimer={""}>

      <Accordion props={posts} />

    </Layout>
  )
}

export default Blog